interface O365ServiceWorkerRegistrationOptions {
    type?: WorkerType,
    updateViaCache?: ServiceWorkerUpdateViaCache
}

export default class O365ServiceWorkerRegistration extends EventTarget {
    private static _instance?: O365ServiceWorkerRegistration;

    private version: string = '0';

    static get instance(): O365ServiceWorkerRegistration {
        if (
            O365ServiceWorkerRegistration._instance === undefined ||
            O365ServiceWorkerRegistration._instance === null
        ) {
            throw Error('Instance have not been initialized yet');
        }

        return O365ServiceWorkerRegistration._instance;
    }

    private options: O365ServiceWorkerRegistrationOptions = {
        type: 'classic',
        updateViaCache: 'all'
    };

    private readonly EVENTS = new Set([
        'onServiceWorkerNotSupported',
        'onBeforeServiceWorkerRegistration',
        'onServiceWorkerRegistered',
        'onBeforeServiceWorkerUpdating',
        'onServiceWorkerUpdated',
        'onServiceWorkerUpdateFound',
        'onServiceWorkerWaitingToActivate',
        'onServiceWorkerActivated',
        'onServiceWorkerFailedToInstall',
        'onServiceWorkerFailedToUpdate',
        'onServiceWorkerUnregistered',
        'onServiceWorkerFailedToUnregister',
        'onServiceWorkerUpdateStateChangeParsed',
        'onServiceWorkerUpdateStateChangeInstalling',
        'onServiceWorkerUpdateStateChangeInstalled',
        'onServiceWorkerUpdateStateChangeActivating',
        'onServiceWorkerUpdateStateChangeActivated',
        'onServiceWorkerUpdateStateChangeRedundant'
    ]);

    get serviceWorkerSuported(): boolean {
        return 'serviceWorker' in window.navigator;
    }

    get serviceWorkerNotSupported(): boolean {
        return !this.serviceWorkerSuported;
    }

    get serviceWorkerContainer(): ServiceWorkerContainer {
        if (this.serviceWorkerNotSupported) {
            throw new Error('Service Worker not supported');
        }

        return window.navigator.serviceWorker;
    }

    get registeredServiceWorker(): Promise<ServiceWorkerRegistration | undefined> {
        return this.serviceWorkerContainer.getRegistration(this.serviceWorkerScope);
    }

    private get serviceWorkerId(): string {
        const o365ServiceWorkerIdMetaElement = document.querySelector('meta[name=o365-service-worker-id]');

        if (o365ServiceWorkerIdMetaElement instanceof HTMLMetaElement) {
            return o365ServiceWorkerIdMetaElement.content;
        }

        throw Error('Failed to find a valid meta tag with the name "o365-service-worker-id"');
    }

    private get serviceWorkerUrl(): string {
        return `/nt/service-worker/${this.serviceWorkerId}?version=${this.version}&timestamp=${new Date().toISOString()}`;
    }

    private get serviceWorkerUrlPathnameWithoutFingerprint(): string {
        return `/nt/service-worker/${this.serviceWorkerId}`;
    }

    private get serviceWorkerScope(): string {
        const scope = self.location.pathname;

        if (typeof scope !== 'string' || scope.split('/').filter((pathPart) => pathPart.length > 0).length === 0) {
            throw new Error('Failed to retrieve a valid scope');
        }

        return scope;
    }

    public get serviceWorkerRegistered(): boolean {
        return this.registeredServiceWorker !== undefined;
    }

    constructor(options: O365ServiceWorkerRegistrationOptions = {}) {
        if (O365ServiceWorkerRegistration._instance instanceof O365ServiceWorkerRegistration) {
            return O365ServiceWorkerRegistration._instance;
        }

        super();

        Object.assign(this.options, options);

        O365ServiceWorkerRegistration._instance = this;
    }

    async initialize(scope: string = '/', version?: string): Promise<ServiceWorkerRegistration | null> {
        if (version) {
            this.version = version;
        }

        return await this.registerServiceWorker(false, scope, version);
    }

    async registerServiceWorker(forceUpdate: boolean = false, scope: string = '/', version?: string): Promise<ServiceWorkerRegistration | null> {
        try {
            if (version) {
                this.version = version;
            }

            if (this.serviceWorkerNotSupported) {
                super.dispatchEvent(new CustomEvent('onServiceWorkerNotSupported'));
                return null;
            }

            const serviceWorkerUrl = forceUpdate ? `${window.location.origin}${this.serviceWorkerUrl}` : this.serviceWorkerUrl;

            const scopeAlreadyRegisterdResult = await this.scopeAlreadyRegistered(scope);

            var serviceWorkerRegistration: ServiceWorkerRegistration | undefined;

            if (scopeAlreadyRegisterdResult) {
                serviceWorkerRegistration = await this.serviceWorkerContainer.getRegistration();
            } else {
                super.dispatchEvent(new CustomEvent('onBeforeServiceWorkerRegistration'));

                serviceWorkerRegistration = await this.serviceWorkerContainer.register(serviceWorkerUrl, {
                    scope: scope,
                    type: this.options.type ?? 'classic',
                    updateViaCache: this.options.updateViaCache ?? 'all'
                });

                serviceWorkerRegistration = await this.serviceWorkerContainer.ready;

                await new Promise((resolve, reject) => {
                    const serviceWorker = serviceWorkerRegistration?.installing ?? serviceWorkerRegistration?.waiting ?? serviceWorkerRegistration?.active;

                    if (serviceWorker === undefined || serviceWorker === null) {
                        return reject(new Error('Could not find Service Worker'));
                    }

                    // let serviceWorkerStateCheckInterval = setInterval(() => {
                    //     const serviceWorker2 = serviceWorkerRegistration?.installing ?? serviceWorkerRegistration?.waiting ?? serviceWorkerRegistration?.active ?? this.serviceWorkerContainer.controller;

                    //     if (serviceWorker2 === undefined || serviceWorker2 === null) {
                    //         return;
                    //     }

                    //     if (serviceWorker2.state === 'activated') {
                    //         clearInterval(serviceWorkerStateCheckInterval);
                    //         serviceWorker.onstatechange = null;
                    //         resolve(undefined);
                    //     }
                    // }, 500);

                    serviceWorker.onstatechange = (event) => {
                        if (event.target?.state === 'activated') {
                            // clearInterval(serviceWorkerStateCheckInterval);
                            serviceWorker.onstatechange = null;
                            resolve(undefined);
                        }
                    }

                    if (serviceWorker.state === 'activated') {
                        serviceWorker.onstatechange = null;
                        resolve(undefined);
                    }
                });

                super.dispatchEvent(new CustomEvent('onServiceWorkerRegistered', {
                    bubbles: false,
                    cancelable: false,
                    composed: false,
                    detail: {
                        serviceWorkerRegistration: serviceWorkerRegistration
                    }
                }));
            }

            if (serviceWorkerRegistration === undefined) {
                return null;
            }

            serviceWorkerRegistration.addEventListener('updatefound', () => {
                super.dispatchEvent(new CustomEvent('onServiceWorkerUpdateFound'));

                const newServiceWorker = serviceWorkerRegistration!.installing;

                newServiceWorker!.addEventListener('statechange', () => {
                    switch (newServiceWorker!.state) {
                        case 'parsed':
                            super.dispatchEvent(new CustomEvent('onServiceWorkerUpdateStateChangeParsed'));
                            break;
                        case 'installing':
                            super.dispatchEvent(new CustomEvent('onServiceWorkerUpdateStateChangeInstalling'));
                            break;
                        case 'installed':
                            super.dispatchEvent(new CustomEvent('onServiceWorkerUpdateStateChangeInstalled'));
                            break;
                        case 'activating':
                            super.dispatchEvent(new CustomEvent('onServiceWorkerUpdateStateChangeActivating'));
                            break;
                        case 'activated':
                            super.dispatchEvent(new CustomEvent('onServiceWorkerUpdateStateChangeActivated'));
                            break;
                        case 'redundant':
                            super.dispatchEvent(new CustomEvent('onServiceWorkerUpdateStateChangeRedundant'));
                            break;
                    }
                });
            });

            return serviceWorkerRegistration;
        } catch (reason) {
            window['console'].error(reason);

            super.dispatchEvent(new CustomEvent('onServiceWorkerFailedToInstall', {
                bubbles: false,
                cancelable: false,
                composed: false,
                detail: {
                    error: reason
                }
            }));
        }

        return null;
    }

    async updateServiceWorker(): Promise<void> {
        try {
            var serviceWorkerRegistration = await this.serviceWorkerContainer.getRegistration();

            if (serviceWorkerRegistration === undefined) {
                serviceWorkerRegistration = await this.registerServiceWorker() ?? undefined;
            } else {
                await serviceWorkerRegistration.update();

                super.dispatchEvent(new CustomEvent('onServiceWorkerUpdated', {
                    bubbles: false,
                    cancelable: false,
                    composed: false,
                    detail: {
                        serviceWorkerRegistration: serviceWorkerRegistration
                    }
                }));
            }

            serviceWorkerRegistration?.addEventListener('updatefound', () => {
                super.dispatchEvent(new CustomEvent('onServiceWorkerUpdateFound'));
            });
        } catch (reason) {
            window['console'].error(reason);

            super.dispatchEvent(new CustomEvent('onServiceWorkerFailedToUpdate', {
                bubbles: false,
                cancelable: false,
                composed: false,
                detail: {
                    error: reason
                }
            }));
        }
    }

    async unregisterServiceWorker(): Promise<void> {
        try {
            const serviceWorkerRegistration = await this.serviceWorkerContainer.getRegistration();

            await serviceWorkerRegistration?.unregister();

            super.dispatchEvent(new CustomEvent('onServiceWorkerUnregistered'));
        } catch (reason) {
            window['console'].error(reason);

            super.dispatchEvent(new CustomEvent('onServiceWorkerFailedToUnregister', {
                bubbles: false,
                cancelable: false,
                composed: false,
                detail: {
                    error: reason
                }
            }));
        }
    }

    private async scopeAlreadyRegistered(providedScope: string): Promise<boolean> {
        const serviceWorkerRegistrations = await this.serviceWorkerContainer.getRegistrations();

        for (const serviceWorkerRegistration of serviceWorkerRegistrations) {
            const registeredScopeUrl = serviceWorkerRegistration.scope,
                registeredScopeUri = new URL(registeredScopeUrl),
                registeredScope = registeredScopeUri.pathname;

            const registeredScriptUrl = serviceWorkerRegistration.active?.scriptURL,
                registeredScriptUri = registeredScriptUrl === undefined ? undefined : new URL(registeredScriptUrl),
                registeredScriptPath = registeredScriptUri?.pathname,
                registeredVersion = registeredScriptUri?.searchParams.get('version');

            if (registeredScope === providedScope && registeredScriptPath === this.serviceWorkerUrlPathnameWithoutFingerprint && registeredVersion === this.version) {
                return true;
            } else {
                await serviceWorkerRegistration.unregister();
            }
        }

        return false;
    }

    addEventListener(event: string, callback: EventListenerOrEventListenerObject) {
        if (this.EVENTS.has(event) === false) {
            throw Error(`Failed to register event listener. Event '${event}' does not exist on ServiceWorkerRegistration`);
        }

        super.addEventListener(event, callback);
    }

    removeEventListener(event: string, callback: EventListenerOrEventListenerObject) {
        if (this.EVENTS.has(event) === false) {
            throw Error(`Failed to remove event listener. Event '${event}' does not exist on ServiceWorkerRegistration`);
        }

        super.removeEventListener(event, callback);
    }
}
